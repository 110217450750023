<template>
  <el-menu
    :collapse="navCollapse"
    router
    class="menu"
    :default-active="activePath"
  >
    <el-submenu v-if="navCollapse" index="/">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </template>
      <el-menu-item index="/" :class="[{ 'active-menu': $route.path === '/' }]">
        <template slot="title">
          <span slot="title">首页</span>
        </template>
      </el-menu-item>
    </el-submenu>
    <el-menu-item
      v-else
      index="/"
      :class="[{ 'active-menu': $route.path === '/' }]"
    >
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </template>
    </el-menu-item>
    <el-submenu
      v-for="menu in visibleMenus"
      :key="menu.title"
      :index="menu.index"
    >
      <template slot="title">
        <i :class="'el-icon-' + menu.icon"></i>
        <span slot="title">{{ menu.title }}</span>
      </template>
      <el-menu-item
        v-for="item in menu.menuItems"
        :key="item.path"
        :index="item.path"
        :class="[
          {
            'active-menu': activePath == item.path,
          },
        ]"
      >
        {{ item.name }}
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
const menus = [
  {
    title: '诊所',
    icon: 'rank',
    index: 'store',
    menuItems: [{ path: '/store', name: '诊所列表' }],
  },
  {
    title: '会员管理',
    icon: 'user',
    index: 'user',
    menuItems: [{ path: '/user', name: '会员列表' }],
  },
  {
    title: '员工',
    icon: 'setting',
    index: 'staff',
    menuItems: [{ path: '/staff', name: '员工列表' }],
  },
  {
    title: 'POS 商品',
    icon: 'goods',
    index: 'goods',
    menuItems: [
      { path: '/category', name: 'POS 商品分类' },
      { path: '/product', name: 'POS 商品' },
    ],
  },
  // {
  //   title: '会员',
  //   icon: 'picture-outline',
  //   index: 'vip',
  //   menuItems: [{ path: '/vip', name: '会员等级设置' }],
  // },
  {
    title: '药材管理',
    icon: 's-shop',
    index: 'drug',
    menuItems: [{ path: '/drug', name: '药材管理' }],
  },
  {
    title: '优惠券',
    icon: 'coffee',
    index: 'coupon_template',
    menuItems: [{ path: '/coupon_template', name: '优惠券' }],
  },
  {
    title: '会员卡',
    icon: 'present',
    index: 'member_card_template',
    menuItems: [
      { path: '/member_card_template', name: '会员卡' },
      { path: '/member_card_orders', name: '购买记录' },
    ],
  },
  {
    title: '余额充值',
    icon: 'potato-strips',
    index: 'recharge_orders',
    menuItems: [{ path: '/recharge_orders', name: '充值记录' }],
  },
  {
    title: '会员消费',
    icon: 's-cooperation',
    index: 'consume_records',
    menuItems: [{ path: '/consume_records', name: '消费记录' }],
  },
  {
    title: '医生特权',
    icon: 'date',
    index: 'privilege',
    menuItems: [{ path: '/privilege', name: '医生特权' }],
  },
  {
    title: '服务项目',
    icon: 'printer',
    index: 'service',
    menuItems: [{ path: '/services', name: '服务项目' }],
  },
  {
    title: '网上商城',
    icon: 's-goods',
    index: 'mall',
    menuItems: [
      { path: '/goods', name: '商品管理' },
      { path: '/goods_order?status=PAID', name: '订单管理' },
    ],
  },
]
export default {
  name: 'Menu',
  props: {
    navCollapse: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      menus,
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['mallOnly']),
    visibleMenus() {
      if (!this.user) {
        return []
      }
      // 这两个账号只看电商
      if (this.mallOnly) {
        return menus.filter(
          menu => menu.index == 'mall' || menu.index == 'goods'
        )
      }
      return menus
    },
    activePath() {
      if (this.$route.path === '/') {
        return '/'
      }
      const activeMenu = this.menus
        .flatMap(item => item.menuItems)
        .find(menu => this.$route.path.startsWith(menu.path))
      if (!activeMenu) {
        throw `${this.$route.path}没有对应的menu`
      }
      return activeMenu.path
    },
  },
  watch: {
    visibleMenus() {
      if (this.$route.path === '/') {
        return
      }
      const activeMenu = this.getActiveMenu(this.visibleMenus)
      if (!activeMenu) {
        this.$router.replace('/')
      }
    },
  },
  methods: {
    getActiveMenu(menus) {
      return menus
        .flatMap(item => item.menuItems)
        .find(menu => this.$route.path.startsWith(menu.path))
    },
  },
}
</script>
<style lang="scss" scoped>
.menu:not(.el-menu--collapse) {
  width: 200px;
}

.menu {
  height: 100%;
  background: #fff;
}
.active-menu {
  background-color: #ebf5ff;
  @apply border-primary-dark border-r-4;
}
</style>
