var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-container",
    [
      _c("el-header", [_vm._v("首页")]),
      !_setup.mallOnly
        ? _c(
            "el-card",
            [
              _c(
                "div",
                { staticClass: "flex gap-4" },
                _vm._l(_setup.exportItems, function (item) {
                  return _c(
                    "el-button",
                    {
                      key: item.type,
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          item.showDialog = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.buttonLabel))]
                  )
                }),
                1
              ),
              _vm._l(_setup.exportItems, function (item) {
                return _c(
                  "el-dialog",
                  {
                    key: item.type,
                    attrs: {
                      width: "500px",
                      visible: item.showDialog,
                      title: item.dialogTitle,
                    },
                    on: {
                      "update:visible": function ($event) {
                        return _vm.$set(item, "showDialog", $event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          attrs: { type: "month", placeholder: "选择月份" },
                          model: {
                            value: item.selectedMonth,
                            callback: function ($$v) {
                              _vm.$set(item, "selectedMonth", $$v)
                            },
                            expression: "item.selectedMonth",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-4",
                            attrs: { loading: item.exporting, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _setup.exportReport(item)
                              },
                            },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-red-400 mt-5" }, [
                      _vm._v("月报表导出时间可能较长，请耐心等待"),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }