import Vue from 'vue'
import { inject } from 'vue'
import Vuex from 'vuex'
import api from './api'

Vue.use(Vuex)

type User = {
  mobile: string
}

const store = new Vuex.Store<{
  user: User | null
  isLogin: boolean
}>({
  state: {
    user: null,
    isLogin: !!localStorage.getItem('token'),
  },
  getters: {
    mallOnly(state) {
      return state.user && state.user.mobile == '18981180287'
    },
  },
  actions: {
    async login({ commit }, form) {
      return api.login(form).then(response => {
        localStorage.setItem('token', response.data.token)
        commit('login')
      })
    },
  },
  mutations: {
    login(state) {
      state.isLogin = true
    },
  },
})
export default store

export const useStore = () => inject<typeof store>('store')!
